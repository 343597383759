@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Avenir";
  src: url("assets/fonts/AvenirLTStd-Book.otf");
  src: url("assets/fonts/AvenirLTStd-Book.otf?#iefix")
      format("embedded-opentype"),
    url("assets/fonts/AvenirLTStd-Book.woff2") format("woff2"),
    url("assets/fonts/AvenirLTStd-Book.woff") format("woff"),
    url("assets/fonts/AvenirLTStd-Book.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

* {
  margin: 0;
}

.primary-teal {
  background-color: #0097a7;
}

.primary-teal-text {
  color: #0097a7;
}

.yellow-bg {
  background: "#F4E04D";
}

.users-banner {
  background: rgb(87, 0, 255);
  background: linear-gradient(
    308deg,
    rgba(87, 0, 255, 1) 0%,
    rgba(91, 97, 245, 1) 65%
  );
  /* width: 100px; */
}

.funder {
  background: #336a81;
}

.orders-full {
  background: rgb(255, 203, 87);
  background: linear-gradient(
    180deg,
    rgba(255, 203, 87, 1) 0%,
    rgba(255, 236, 193, 1) 65%
  );
  /* background: ; */
}

.bg-orange-index {
  transform: rotate(-1deg);
}

@layer base {
  :root {
    --primary-color: 0, 151, 167;
  }

  .aspiro {
    --primary-color: 136, 35, 35;
  }
}

body {
  font-family: "Avenir", "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.9rem;
  color: #4d4f5c;
}

.list-btn {
  @apply w-full py-4 px-1 flex justify-between items-center border-t last:border-b hover:bg-gray-100;
}

.overflow-y-visible-important {
  overflow-y: visible !important;
}

/* New styles */
.add-bank-modal {
  inset: 40% auto auto 19% !important;
  transform: translate(-50%, -50%);
}
