.page-sidebar {
  transition: transform .3s cubic-bezier(0, .52, 0, 1);

  .sidebar-menu {
    overflow: auto;
    color: #5a636b;
    font-weight: bold;

    ul.menu-items {
      li {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        padding: 1rem 1rem 1rem 2rem;
        transition: .3s all ease-in;
  
        svg {
          margin-right: 10px;
          width: 20px;
          height: auto;
        }
      }
  
      li.active,
      li:hover {
        color: #FFFFFF;
        position: relative;

        &:before {
          position: absolute;
          content: "";
          display: block;
          left: 0;
          top: 0;
          height: 100%;
          // width: 8px;
          background: #E9EDFE;
        }
      }

      ul.sub-menu {
        li {
          padding: 0.5rem 0.5rem 0.5rem 4rem !important;
        }
      }
    }
  }
  
  &.show {
    transform: translate3d(0rem, 0, 0) !important;
  }

  @media (max-width: 1023.5px) {
    // Hide Menu
    transform: translate3d(-16rem, 0, 0);
  }
}


// New styles
.verify-txt {
  color: #f1c40f;
}