.form-group {
  .select {
    .react-select__control {
      line-height: 1.25;
      --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
      color: rgba(55, 65, 81, 1);
      border: 1px solid rgba(209, 213, 219, 1);
      border-radius: 0.25rem;
      -webkit-appearance: none;
      appearance: none;
      height: 52px;
    }
  }
}

.my-custom-checkbox {
  position: relative;
  padding: 0 0 0 30px;
  text-align: left;

  label {
    cursor: pointer;
    margin-top: 2px;

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 15px;
      border-radius: 5px;
    }
    &:before {
      top: 3px;
      left: 0;
      width: 18px;
      height: 18px;
      margin: 0;
      background: #f7f7f7;
      border: 1px solid #2050F5;
      box-shadow: 0 0 1px #2050F5;
    }
    &:after {
      left: 2px;
      width: 14px;
      height: 14px;
      margin: -10px 0 0;
      opacity: 0;
      background: #2050F5;
      transform: translate3d(-40px, 0, 0) scale(0.5);
      transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
    }
  }

  input[type="checkbox"] {
    position: absolute;
    top: 0;
    left: -9999px;
    visibility: hidden;

    &:checked + label {
      &:after {
        transform: translate3d(0, 0, 0);
        opacity: 1;
      }
    }
  }
}
